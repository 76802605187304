<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card :color="dark" v-if="getOrderDetail">
      <v-container>
        <v-card-title class="">
          <h1>Order Details</h1>
          <v-spacer></v-spacer>
          <v-btn
            icon
            outlined
            text
            large
            tile
            class="white--text rounded-lg mr-3"
            @click="editOrder"
            :loading="loading"
            v-if="getOrderDetail.order_status !== 'closed' && getSession.user_type !== 2"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            icon
            outlined
            text
            large
            tile
            class="white--text rounded-lg"
            @click="$emit('close', false)"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-spacer></v-spacer>

        <v-card :color="ant" class="ml-5 mr-4">
          <v-card-title class="card-header">
            Summary
          </v-card-title>

          <v-card-text class="text-body-1">
            <table class="ml-0 mt-5 ">
              <tr>
                <td>Assignment Type:</td>
                <td>{{ getOrderDetail.assignment_type }}</td>
              </tr>
              <tr class="">
                <td>Service:</td>
                <td>{{ getOrderDetail.services }}</td>
              </tr>
              <tr>
                <td>Pages:</td>
                <td>{{ getOrderDetail.pages }}</td>
              </tr>
              <tr>
                <td>Academic Level:</td>
                <td>{{ getOrderDetail.level }}</td>
              </tr>
              <tr>
                <td>Language:</td>
                <td>{{ getOrderDetail.language }}</td>
              </tr>
            </table>

            <v-divider></v-divider>
            <table class="ml-0">
              <tr>
                <td>Final Due Date:</td>
                <td :class="{'red--text': timeErrorStatus(getOrderDetail.final_date) && getOrderDetail.order_status !== 'closed' }">{{ formatDateMonth(getOrderDetail.final_date) }}</td>
              </tr> 
            </table>

            <v-divider></v-divider>
            <table>
              <tr>
                <td>Assignment Topic:</td>
                <td>{{ getOrderDetail.topic }}</td>
              </tr>
              <tr>
                <td>Subject:</td>
                <td>{{ getOrderDetail.subject }}</td>
              </tr>
              <tr>
                <td>Sources:</td>
                <td>{{ getOrderDetail.sources }}</td>
              </tr>
              <tr>
                <td>Citation Style:</td>
                <td>{{ getOrderDetail.citation }}</td>
              </tr>
            </table>

            <v-divider class="mb-5"></v-divider>
            <v-card-actions v-if="false">
              <v-btn text outlined large>
                Archive Order
              </v-btn>
              <v-spacer></v-spacer>

              <v-icon>
                mdi-dots-vertical-circle
              </v-icon>
              <v-btn text outlined large class="ml-5">
                create similar Order
              </v-btn>

              <v-icon class="ml-5">
                mdi-dots-vertical-circle
              </v-icon>
              <v-btn text outlined large class="ml-5" v-if="getOrderDetail.order_status === '2'">
                Resubmit Order
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>

        <v-card :color="ant" class="ml-5 mr-4 mt-5">
          <v-card-title class="card-header">
            Upload Files
          </v-card-title>

          <v-card-text>
            <fileList :files="getOrderDetail.file" v-if="getUploadedFile.length !== 0"/>
            <div class="" v-else >
              <v-img :src="src" class="mt-0" contain height="100px" />
              <div class="text-center mx-5">
                <P>
                  You have not Uploaded any Files.
                </P>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card :color="ant" class="ml-5 mr-4 mt-5">
          <v-card-title class="card-header">
            Instructions
          </v-card-title>

          <v-card-text class="mt-5">
            <p>
              {{ getOrderDetail.instruction }}
            </p>
          </v-card-text>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";
import { mapGetters } from 'vuex';
import timezoneMxn from '@/mixins/timezone_mixin';

export default {
  name: "OrderViewModal",
  components: {
    fileList: () => import("@/modules/Orders/components/fileList")
  },
  props: ["show"],
  mixins: [colorMxn, timezoneMxn],
  data() {
    return {
      src: require('@/assets/img/svg/no-notifications.svg'),
      dialogue: false,
      after: true,
      uploaded: true,
      order: {
        assignment_type: "Math Assignment",
        services: "Writing",
        pages: "1 Page",
        level: "COllege",
        language: "English",
        final_date: "Aug 11, 2020, 01:58 PM ",
        topic: "Machine learning",
        subject: "Data Science",
        sources: "3 Sources Required",
        citation: "APA 6th edition",
        files: [
          {
            name: "Profile Picture",
            size: "5.4 KB",
            time: "Jan 11, 01:10 AM"
          }
        ],
        instruction:
          "Machine learning model using python scikit-learn library, create confusion matrix and calculate the accuracy."
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['getOrderDetail', 'getUploadedFile', 'getSession']),
  },
  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    editOrder() {
      this.loading = true;
      this.$router.push(`/order/draft/${this.$route.params.id}`)
    }
  }
};
</script>

<style scoped>
tr,
td {
  padding: 5px;
}
</style>
